import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const ExcursionDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [values, setValues] = useState({
    name: '',
    description: '',
    location: '',
    price: '',
    image: '',
    country: '',
    imageURL: '',
  });
  const [country, setCountry] = useState([]);
  const [image, setImage] = useState(null);

  const token = localStorage.getItem('token');

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountry(data);
  };

  const getExcursionDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/excursion/${id}`, { headers: { token } });
    const data = await res.json();
    setValues({
      name: data.excursion.ExcursionName,
      description: data.excursion.ExcursionDescription,
      image: data.excursion.ExcursionImage,
      location: data.excursion.ExcursionLocation,
      price: data.excursion.ExcursionPrice,
      country: data.excursion.CountryName,
      imageURL: data.excursion.ExcursionImageURL,
    });
  };

  useEffect(() => {
    getExcursionDetails();
    getCountryNames();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const imageHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (image) {
      const formData = new FormData();
      formData.append('file', image);
      try {
        const res = await fetch(`${process.env.REACT_APP_APILINK}/excursion/uploadimage`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const imageData = await res.json();

        if (imageData.message) {
          setOpenToast(true);
          setToastmessage(imageData.message);
        }

        if (imageData.success) {
          // setValues({ ...values, image: imageData.image });
          values.image = imageData.image;
        }
      } catch (error) {
        console.log(error);
      }
    }

    // Update Values
    const res = await fetch(`${process.env.REACT_APP_APILINK}/excursion/${id}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        token,
      },
      body: JSON.stringify(values),
    });
    const data = await res.json();

    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        setTimeout(() => {
          navigate('/excursions');
        }, 2000);
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title={values.name} subtitle="Country Details" />

        <Box>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Go Back
          </Button>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '1rem',
            paddingBottom: '20px',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Country"
            onChange={handleChange}
            value={values.country}
            name="country"
            select
          >
            {country &&
              country.map((country) => (
                <MenuItem key={country.CountryID} value={country.CountryName}>
                  {country.CountryName}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Excursion Name"
            onChange={handleChange}
            value={values.name}
            name="name"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Description"
            onChange={handleChange}
            value={values.description}
            name="description"
            required
            multiline
            rows={2}
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Location"
            onChange={handleChange}
            value={values.location}
            name="location"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="price"
            onChange={handleChange}
            value={values.price}
            name="price"
            required
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              backgroundColor: colors.primary[400],
              padding: '15px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <Typography variant="h5" mt="10px" color={colors.grey[100]}>
                Excursion Image :
              </Typography>
              <Button
                onClick={() => {
                  setValues({ ...values, imageURL: '' });
                }}
                variant="contained"
                sx={{
                  backgroundColor: colors.primary[500],
                  color: colors.grey[100],
                  width: 'fit-content',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  padding: '8px 15px',
                  '&:hover': {
                    backgroundColor: colors.grey[700],
                  },
                }}
              >
                Remove Image
              </Button>
            </Box>
            <label
              htmlFor="file-input"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                background: colors.primary[400],
                fontSize: '18px',
                fontWeight: 'bold',
                cursor: 'pointer',
                borderRadius: '10px',
                width: '100%',
                maxWidth: '400px',
                aspectRatio: '16/9',
              }}
            >
              {values.imageURL ? (
                <img
                  src={values.imageURL}
                  alt={values.name}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : image ? (
                <img
                  src={URL.createObjectURL(image)}
                  alt={values.name}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                <>
                  <CloudUploadIcon fontSize="large" /> Upload Image
                </>
              )}
            </label>
            <input type="file" accept="image/*" onChange={imageHandler} name="image" id="file-input" hidden />
          </Box>
          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Update
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default ExcursionDetails;
