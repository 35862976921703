import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const AddPromotion = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const token = localStorage.getItem('token');

  const [values, setValues] = useState({
    OfferName: '',
    OfferDescription: '',
    OfferStatus: '',
    OfferImage: '',
  });
  const [image, setImage] = useState(false);

  const imageHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (values.OfferName === '' || values.OfferDescription === '' || values.OfferStatus === '') {
      setOpenToast(true);
      setToastmessage('Please fill all details');
      return;
    }
    if (image) {
      const formData = new FormData();
      formData.append('file', image);

      try {
        const res = await fetch(`${process.env.REACT_APP_APILINK}/offers/uploadimage`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const imageData = await res.json();

        if (imageData.message) {
          setOpenToast(true);
          setToastmessage(imageData.message);
        }
        if (imageData.success) {
          values.OfferImage = imageData.image;

          const res = await fetch(`${process.env.REACT_APP_APILINK}/offers/addoffer`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              token,
            },
            body: JSON.stringify(values),
          });
          const data = await res.json();

          if (data.message) {
            setOpenToast(true);
            setToastmessage(data.message);
            if (data.success) {
              setTimeout(() => {
                navigate('/promotion');
              }, 2000);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="ADD PROMOTION" subtitle="Add a New Promotion Offer" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '10px',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Promotion Name"
            onChange={(e) => setValues({ ...values, OfferName: e.target.value })}
            value={values.OfferName}
            name="OfferName"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Promotion Description"
            onChange={(e) => setValues({ ...values, OfferDescription: e.target.value })}
            value={values.OfferDescription}
            name="OfferDescription"
            required
          />

          <FormControl component="fieldset">
            <Typography variant="h5" mt="10px" color={colors.grey[100]}>
              Status
            </Typography>
            <RadioGroup
              aria-label="OfferStatus"
              name="OfferStatus"
              value={values.OfferStatus}
              onChange={(e) => setValues({ ...values, OfferStatus: e.target.value })}
              row
            >
              <FormControlLabel value={1} control={<Radio color="success" />} label="Active" />
              <FormControlLabel value={0} control={<Radio color="success" />} label="Inactive" />
            </RadioGroup>
          </FormControl>

          <Typography variant="h5" mt="10px" color={colors.grey[100]}>
            Add Promotion Image
          </Typography>
          <label
            htmlFor="file-input"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              padding: '15px',
              background: colors.primary[400],
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              borderRadius: '10px',
              width: '100%',
              maxWidth: '400px',
              aspectRatio: '16/9',
            }}
          >
            {image ? (
              <img
                src={URL.createObjectURL(image)}
                className="addproduct-thumbnail-img"
                alt="Image Upload"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <>
                <CloudUploadIcon fontSize="large" /> Upload Image
              </>
            )}
          </label>
          <input type="file" accept="image/*" onChange={imageHandler} name="image" id="file-input" hidden />

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AddPromotion;
