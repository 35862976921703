import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AdminAccess = () => {
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState({
    user: '',
    role: '',
  });

  const checkAuth = async () => {
    const token = localStorage.getItem('token');
    try {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin`, { headers: { token } });
      const data = await res.json();
      if (data && data.success) {
        setAdmin(data.user);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (admin.role !== 1) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default AdminAccess;
