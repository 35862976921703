import { Box, Button, IconButton, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const AddGuide = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [countryNames, setCountryNames] = useState([]);
  const [image, setImage] = useState(null);
  const token = localStorage.getItem('token');

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountryNames(data);
  };

  useEffect(() => {
    getCountryNames();
  }, []);

  const handleFormSubmit = async (values) => {
    if (!image) {
      setOpenToast(true);
      setToastmessage('Choose an Image');
      return;
    }
    const formData = new FormData();
    formData.append('file', image);

    try {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/guides/uploadimage`, {
        headers: { token },
        method: 'POST',
        body: formData,
      });
      const imageData = await res.json();

      if (imageData.message) {
        setOpenToast(true);
        setToastmessage(imageData.message);
      }
      if (imageData.success) {
        values.GuideImage = imageData.image;

        const res = await fetch(`${process.env.REACT_APP_APILINK}/guides`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            token,
          },
          body: JSON.stringify(values),
        });
        const data = await res.json();

        if (data.message) {
          setOpenToast(true);
          setToastmessage(data.message);
          if (data.success) {
            setTimeout(() => {
              navigate('/guide');
            }, 2000);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="ADD GUIDE" subtitle="Add a New Guide" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuideName}
                name="GuideName"
                error={!!touched.GuideName && !!errors.GuideName}
                helperText={touched.GuideName && errors.GuideName}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Surname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuideSurname}
                name="GuideSurname"
                error={!!touched.GuideSurname && !!errors.GuideSurname}
                helperText={touched.GuideSurname && errors.GuideSurname}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Gender"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuideGender}
                name="GuideGender"
                select
                error={!!touched.GuideGender && !!errors.GuideGender}
                helperText={touched.GuideGender && errors.GuideGender}
                sx={{ gridColumn: 'span 2' }}
              >
                <MenuItem value={'m'}>Male</MenuItem>
                <MenuItem value={'f'}>Female</MenuItem>
                <MenuItem value={'o'}>Other</MenuItem>
              </TextField>

              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Date of Birth"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuideDob}
                name="GuideDob"
                error={!!touched.GuideDob && !!errors.GuideDob}
                helperText={touched.GuideDob && errors.GuideDob}
                sx={{ gridColumn: 'span 2', colorScheme: 'dark' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuideEmail}
                name="GuideEmail"
                error={!!touched.GuideEmail && !!errors.GuideEmail}
                helperText={touched.GuideEmail && errors.GuideEmail}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuidePassword}
                name="GuidePassword"
                error={!!touched.GuidePassword && !!errors.GuidePassword}
                helperText={touched.GuidePassword && errors.GuidePassword}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuidePhone}
                name="GuidePhone"
                error={!!touched.GuidePhone && !!errors.GuidePhone}
                helperText={touched.GuidePhone && errors.GuidePhone}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Location"
                onChange={handleChange}
                value={values.CountryID}
                name="CountryID"
                onBlur={handleBlur}
                error={!!touched.CountryID && !!errors.CountryID}
                helperText={touched.CountryID && errors.CountryID}
                sx={{ gridColumn: 'span 4' }}
                select
              >
                {countryNames &&
                  countryNames.map((country) => (
                    <MenuItem key={country.CountryID} value={country.CountryID}>
                      {country.CountryName}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuideAddress}
                name="GuideAddress"
                error={!!touched.GuideAddress && !!errors.GuideAddress}
                helperText={touched.GuideAddress && errors.GuideAddress}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="License"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuideLicense}
                name="GuideLicense"
                error={!!touched.GuideLicense && !!errors.GuideLicense}
                helperText={touched.GuideLicense && errors.GuideLicense}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Vehicle No"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GuideVehicleNo}
                name="GuideVehicleNo"
                error={!!touched.GuideVehicleNo && !!errors.GuideVehicleNo}
                helperText={touched.GuideVehicleNo && errors.GuideVehicleNo}
                sx={{ gridColumn: 'span 4' }}
              />
              <Box>
                <Typography variant="h5" mb="5px" color={colors.grey[100]}>
                  Add Guide Image
                </Typography>
                <label
                  htmlFor="file-input"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    padding: '15px',
                    background: colors.primary[400],
                    fontSize: '18px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    width: '100%',
                    maxWidth: '250px',
                    aspectRatio: '1/1',
                  }}
                >
                  {image ? (
                    <img
                      src={URL.createObjectURL(image)}
                      className="addproduct-thumbnail-img"
                      alt="Image Upload"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  ) : (
                    <>
                      <CloudUploadIcon fontSize="large" /> Upload Image
                    </>
                  )}
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                  name="image"
                  id="file-input"
                  hidden
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                ADD GUIDE
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  GuideName: yup.string().required('required'),
  GuideSurname: yup.string().required('required'),
  GuideGender: yup.string().required('required'),
  GuideDob: yup.date().required('required'),
  GuideEmail: yup.string().email('invalid email').required('required'),
  GuidePhone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('required'),
  CountryID: yup.string().required('required'),
  GuideAddress: yup.string().required('required'),
  GuideLicense: yup.string().required('required'),
  GuideVehicleNo: yup.string().required('required'),
  GuidePassword: yup.string().required('required'),
});
const initialValues = {
  GuideName: '',
  GuideSurname: '',
  GuideGender: '',
  GuideDob: '',
  GuideEmail: '',
  GuidePhone: '',
  CountryID: '',
  GuideAddress: '',
  GuideLicense: '',
  GuideVehicleNo: '',
  GuidePassword: '',
};

export default AddGuide;
