import { Box, Button, CircularProgress, IconButton, Snackbar, useTheme } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Header from '../../components/Header';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const AdminUsers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');

  const getData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/adminusers`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setAllUsers(data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  // Delete Admin user
  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/adminusers/${id}`, {
      headers: { token },
      method: 'DELETE',
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        getData();
      }
    }
  };

  const columns = [
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'CountryName',
      headerName: 'Location',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'Edit',
      renderCell: (cellValues) => {
        return (
          <Link to={`/adminusers/${cellValues.row.id}`}>
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.greenAccent[700] },
              }}
              variant="contained"
            >
              <EditIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Delete',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.redAccent[600] },
            }}
            variant="contained"
            onClick={() => handleDelete(cellValues.row.id)}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80vh' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" flexWrap="wrap">
        <Header title="ADMIN USERS" subtitle="Managing all Admin users" />

        <Box display="flex" gap="10px" flexWrap="wrap">
          <Link to={'/adminusers/add'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <PersonOutlinedIcon sx={{ mr: '10px' }} />
              Add Admin
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="667px"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            fontSize: 14,
          },
        }}
      >
        <DataGrid
          rows={allUsers}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: CustomToolbar }}
          autoPageSize
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AdminUsers;
