import { Box, Button, IconButton, InputAdornment, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const AddExcursion = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const [values, setValues] = useState({
    name: '',
    description: '',
    location: '',
    price: '',
    image: '',
    country: '',
  });

  const [image, setImage] = useState(false);

  const [countryNames, setCountryNames] = useState([]);

  const getCountryNames = async () => {
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountryNames(data);
  };

  useEffect(() => {
    getCountryNames();
  }, []);

  const imageHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');

    if (image) {
      const formData = new FormData();
      formData.append('file', image);

      try {
        const imageRes = await fetch(`${process.env.REACT_APP_APILINK}/excursion/uploadimage`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const imageData = await imageRes.json();

        if (imageData.message) {
          setOpenToast(true);
          setToastmessage(imageData.message);
        }
        if (imageData.success) {
          // Update the values with comma-separated image URLs
          values.image = imageData.image;

          const res = await fetch(`${process.env.REACT_APP_APILINK}/excursion/addexcursion`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              token,
            },
            body: JSON.stringify(values),
          });
          const data = await res.json();

          if (data.message) {
            setOpenToast(true);
            setToastmessage(data.message);
            if (data.success) {
              setTimeout(() => {
                navigate('/excursions');
              }, 2000);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="ADD EXCURSION" subtitle="Add a New Excursion Location" />

        <Box>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Go Back
          </Button>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '1.25rem',
            paddingBottom: '20px',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Country"
            onChange={handleChange}
            value={values.country}
            name="country"
            select
          >
            {countryNames &&
              countryNames.map((country) => (
                <MenuItem key={country.CountryID} value={country.CountryName}>
                  {country.CountryName}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Excursion Name"
            onChange={handleChange}
            value={values.name}
            name="name"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Description"
            onChange={handleChange}
            value={values.description}
            name="description"
            required
            multiline
            rows={2}
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Location"
            onChange={handleChange}
            value={values.location}
            name="location"
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="price"
            onChange={handleChange}
            value={values.price}
            name="price"
            required
          />

          <Box>
            <Typography variant="h5" my="5px" px="5px" color={colors.grey[100]}>
              Add Excursion Image
            </Typography>
            <label
              htmlFor="file-input"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                padding: '15px',
                background: colors.primary[400],
                fontSize: '18px',
                fontWeight: 'bold',
                cursor: 'pointer',
                borderRadius: '10px',
                width: '100%',
                maxWidth: '400px',
                aspectRatio: '16/9',
              }}
            >
              {image ? (
                <img
                  src={URL.createObjectURL(image)}
                  alt="Image Upload"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                <>
                  <CloudUploadIcon fontSize="large" /> Upload Image
                </>
              )}
            </label>
            <input type="file" accept="image/*" onChange={imageHandler} name="image" id="file-input" hidden />
          </Box>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AddExcursion;
