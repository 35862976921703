import { Box, Button, CircularProgress, IconButton, Snackbar, useTheme } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';
import VisibilityIcon from '@mui/icons-material/Visibility';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const Messages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');

  const [messages, setMessages] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/message`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setMessages(data.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  // Delete Message
  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/message/${id}`, {
      method: 'DELETE',
      headers: { token },
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        getData();
      }
    }
  };

  const columns = [
    {
      field: 'from_name',
      headerName: 'From',
      flex: 1,
      minWidth: 150,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'to_name',
      headerName: 'To',
      flex: 1,
      minWidth: 150,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1,
      minWidth: 150,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'destination',
      headerName: 'Location',
      flex: 1,
      minWidth: 150,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'guide_name',
      headerName: 'Guide',
      flex: 1,
      minWidth: 150,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'bus',
      headerName: 'Bus',
      flex: 1,
      minWidth: 50,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'time',
      headerName: 'Time',
      flex: 1,
      minWidth: 150,
      cellClassName: 'name-column--cell',
      renderCell: (params) => (
        <p>
          {params.row.date} {params.row.time}
        </p>
      ),
    },
    {
      field: 'View',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Link to={`/message/${cellValues.row.chat_id}`}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[500],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.blueAccent[600] },
              }}
              variant="contained"
            >
              <VisibilityIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Delete',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.redAccent[600] },
            }}
            variant="contained"
            onClick={() => handleDelete(cellValues.row.chat_id)}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80vh' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" flexWrap="wrap">
        <Header title="MESSAGES" subtitle="All Admin Messages" />

        <Box>
          <Link to={'/message/sendmessage'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <MessageIcon sx={{ mr: '10px' }} />
              Send Message
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="667px"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
            minWidth: '300px',
          },
          '& .name-column--cell': {
            color: colors.grey[100],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            fontSize: 14,
          },
        }}
      >
        <DataGrid
          rows={messages}
          columns={columns}
          getRowId={(row) => row.chat_id}
          components={{ Toolbar: CustomToolbar }}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default Messages;
