import { Box, Button, IconButton, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const GuideDetails = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [countryNames, setCountryNames] = useState([]);
  const [guide, setGuide] = useState();
  const [image, setImage] = useState(null);
  const token = localStorage.getItem('token');

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountryNames(data);
  };

  const getGuideDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/guides/${id}`, { headers: { token } });
    const data = await res.json();
    setGuide({
      ...data.guide,
      dob: convertDate(data.guide.dob),
    });
  };

  useEffect(() => {
    getCountryNames();
    getGuideDetails();
  }, []);

  // Function to convert backend date to 'YYYY-MM-DD' format
  const convertDate = (backendDate) => {
    const dateObject = new Date(backendDate);
    return dateObject.toISOString().split('T')[0]; // Extract 'YYYY-MM-DD'
  };

  const handleFormSubmit = async (values) => {
    if (image) {
      const formData = new FormData();
      formData.append('file', image);

      try {
        const res = await fetch(`${process.env.REACT_APP_APILINK}/guides/uploadimage`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const imageData = await res.json();

        if (imageData.message) {
          setOpenToast(true);
          setToastmessage(imageData.message);
        }
        if (imageData.success) {
          values.image = imageData.image;
        }
      } catch (error) {
        console.log(error);
      }
    }

    const res = await fetch(`${process.env.REACT_APP_APILINK}/guides/${id}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        token,
      },
      body: JSON.stringify(values),
    });
    const data = await res.json();

    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        setTimeout(() => {
          navigate('/guide');
        }, 2000);
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="GUIDE DETAILS" subtitle="" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      {guide && (
        <Formik onSubmit={handleFormSubmit} initialValues={guide} validationSchema={checkoutSchema}>
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Surname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.surname}
                  name="surname"
                  error={!!touched.surname && !!errors.surname}
                  helperText={touched.surname && errors.surname}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Gender"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gender}
                  name="gender"
                  select
                  error={!!touched.gender && !!errors.gender}
                  helperText={touched.gender && errors.gender}
                  sx={{ gridColumn: 'span 2' }}
                >
                  <MenuItem value={'m'}>Male</MenuItem>
                  <MenuItem value={'f'}>Female</MenuItem>
                  <MenuItem value={'o'}>Other</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Date of Birth"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dob}
                  name="dob"
                  error={!!touched.dob && !!errors.dob}
                  helperText={touched.dob && errors.dob}
                  sx={{ gridColumn: 'span 2', colorScheme: 'dark' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 4' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="New Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: 'span 4' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  sx={{ gridColumn: 'span 4' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Country"
                  onChange={handleChange}
                  value={values.CountryID}
                  name="CountryID"
                  onBlur={handleBlur}
                  error={!!touched.CountryID && !!errors.CountryID}
                  helperText={touched.CountryID && errors.CountryID}
                  sx={{ gridColumn: 'span 4' }}
                  select
                >
                  {countryNames &&
                    countryNames.map((country) => (
                      <MenuItem key={country.CountryID} value={country.CountryID}>
                        {country.CountryName}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: 'span 4' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="License"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.license}
                  name="license"
                  error={!!touched.license && !!errors.license}
                  helperText={touched.license && errors.license}
                  sx={{ gridColumn: 'span 4' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Vehicle No"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vehicleNo}
                  name="vehicleNo"
                  error={!!touched.vehicleNo && !!errors.vehicleNo}
                  helperText={touched.vehicleNo && errors.vehicleNo}
                  sx={{ gridColumn: 'span 4' }}
                />
                <Box>
                  <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <Typography variant="h5" mb="5px" color={colors.grey[100]}>
                      Guide Image :
                    </Typography>
                    <Button
                      onClick={() => {
                        setGuide({ ...guide, imageURL: '' });
                        setImage(null);
                      }}
                      variant="contained"
                      sx={{
                        backgroundColor: colors.primary[400],
                        color: colors.grey[100],
                        width: 'fit-content',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        padding: '5px 10px',
                        marginBottom: '5px',
                        '&:hover': {
                          backgroundColor: colors.primary[300],
                        },
                      }}
                    >
                      Remove Image
                    </Button>
                  </Box>
                  <label
                    htmlFor="file-input"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      padding: '15px',
                      background: colors.primary[400],
                      fontSize: '18px',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      borderRadius: '10px',
                      width: '100%',
                      maxWidth: '250px',
                      aspectRatio: '1/1',
                    }}
                  >
                    {guide.imageURL ? (
                      <img
                        src={guide.imageURL}
                        alt={guide.name}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    ) : image ? (
                      <img
                        src={URL.createObjectURL(image)}
                        alt={guide.image}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    ) : (
                      <>
                        <CloudUploadIcon fontSize="large" /> Upload Image
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    name="image"
                    id="file-input"
                    hidden
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  UPDATE GUIDE
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required('required'),
  surname: yup.string().required('required'),
  email: yup.string().email('invalid email').required('required'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('required'),
  CountryID: yup.string().required('required'),
  address: yup.string().required('required'),
  license: yup.string().required('required'),
  vehicleNo: yup.string().required('required'),
  password: yup.string(),
});
const initialValues = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  CountryID: '',
  address: '',
  license: '',
  vehicleNo: '',
  password: '',
};

export default GuideDetails;
