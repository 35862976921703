import { Box, Button, IconButton, Snackbar, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaceIcon from '@mui/icons-material/Place';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import KeyIcon from '@mui/icons-material/Key';
import ChangePassword from '../changePassword';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WcIcon from '@mui/icons-material/Wc';
import CakeIcon from '@mui/icons-material/Cake';
import MenuBookIcon from '@mui/icons-material/MenuBook';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const PassengerDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const [passenger, setPassenger] = useState(null);
  const [members, setMembers] = useState(null);

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const handleOpenPasswordModal = () => setOpenPasswordModal(true);
  const handleClosePasswordModal = () => setOpenPasswordModal(false);

  const token = localStorage.getItem('token');

  const getPassengerDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passengers/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setPassenger(data.passenger);
    }
  };

  const getmembersDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passengers/${id}/members`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setMembers(data.members);
    }
  };

  useEffect(() => {
    getPassengerDetails();
    getmembersDetails();
  }, []);

  // Function to convert backend date to 'YYYY-MM-DD' format
  const convertDate = (backendDate) => {
    const dateObject = new Date(backendDate);
    const day = String(dateObject.getDate()).padStart(2, '0'); // Get day and add leading zero if needed
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and add leading zero if needed
    const year = dateObject.getFullYear(); // Get year
    return `${day}-${month}-${year}`; // Return in 'DD-MM-YYYY' format
  };

  // Delete PassengerMember
  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passenger_member/${id}`, {
      headers: { token },
      method: 'DELETE',
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  };

  const columns = [
    // { field: 'M_ID', headerName: 'M_ID' },
    {
      field: 'M_Name',
      headerName: 'Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'M_Surname',
      headerName: 'Surname',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'M_Gender',
      headerName: 'Gender',
      flex: 1,
    },
    {
      field: 'M_Age',
      headerName: 'Age',
      flex: 1,
    },
    {
      field: 'Delete',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.redAccent[600] },
            }}
            variant="contained"
            onClick={() => handleDelete(cellValues.row.M_ID)}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      {passenger && (
        <Box m="20px">
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" flexWrap="wrap">
            <Header title={`${passenger.name} ${passenger.surname}`} subtitle="Passenger Details" />

            <Box display="flex" gap="10px" flexWrap="wrap" mb="20px">
              {/* <Link to={`/passenger/${passenger.id}/addmember`}>
                <Button
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    marginRight: '10px',
                    '&:hover': {
                      backgroundColor: colors.blueAccent[800],
                    },
                  }}
                >
                  <PersonOutlinedIcon sx={{ mr: '10px' }} />
                  Add Member
                </Button>
              </Link> */}

              <Link to="/allpassengers">
                <Button
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    '&:hover': {
                      backgroundColor: colors.blueAccent[800],
                    },
                  }}
                >
                  Go Back
                </Button>
              </Link>
            </Box>
          </Box>

          <Box backgroundColor={colors.primary[400]} borderRadius={5} p="1.5rem">
            {/* Passenger Details */}
            <Box display="flex" flexDirection="column" gap="10px" mb="2rem">
              <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
                Passenger Details
              </Typography>

              <Box display="flex" gap="10px" flexWrap="wrap">
                <Box
                  flex={1}
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                  sx={{ '&:hover': { backgroundColor: '#2D3B55' } }}
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PersonIcon /> Name
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {passenger?.name}
                  </Typography>
                </Box>

                <Box
                  flex={1}
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                  sx={{ '&:hover': { backgroundColor: '#2D3B55' } }}
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PersonIcon /> Surname
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {passenger?.surname}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" gap="10px" flexWrap="wrap">
                <Box
                  flex={1}
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                  sx={{ '&:hover': { backgroundColor: '#2D3B55' } }}
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <MailOutlineIcon /> Email
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {passenger?.email}
                  </Typography>
                </Box>

                <Box
                  flex={1}
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                  sx={{ '&:hover': { backgroundColor: '#2D3B55' } }}
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <LocalPhoneIcon /> Phone Number
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {passenger?.phone}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" gap="10px" flexWrap="wrap">
                <Box
                  flex={1}
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                  sx={{ '&:hover': { backgroundColor: '#2D3B55' } }}
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <WcIcon /> Gender
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {passenger.gender
                      ? passenger.gender === 'm'
                        ? 'Male'
                        : passenger.gender === 'f'
                        ? 'Female'
                        : 'Other'
                      : null}
                  </Typography>
                </Box>

                <Box
                  flex={1}
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                  sx={{ '&:hover': { backgroundColor: '#2D3B55' } }}
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <CakeIcon /> Date of Birth
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {passenger.dob && convertDate(passenger.dob)}
                  </Typography>
                </Box>
              </Box>

              <Box
                border={1}
                borderColor={colors.primary[300]}
                borderRadius="8px"
                p="10px"
                sx={{ '&:hover': { backgroundColor: '#2D3B55' } }}
              >
                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <MenuBookIcon /> Passport
                </Typography>
                <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                  {passenger?.passport}
                </Typography>
              </Box>

              <Box
                border={1}
                borderColor={colors.primary[300]}
                borderRadius="8px"
                p="10px"
                sx={{ '&:hover': { backgroundColor: '#2D3B55' } }}
              >
                <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                  <PlaceIcon /> Address
                </Typography>
                <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                  {passenger?.address}
                </Typography>
              </Box>

              <Button
                onClick={handleOpenPasswordModal}
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  width: 'fit-content',
                  '&:hover': {
                    backgroundColor: colors.blueAccent[800],
                  },
                }}
              >
                <KeyIcon sx={{ mr: '10px' }} /> Change Password
              </Button>

              <ChangePassword open={openPasswordModal} handleClose={handleClosePasswordModal} id={id} />
            </Box>

            {/* Member Details */}
            {/* <Box display="grid" gap="10px">
              <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]}>
                Member Details
              </Typography>

              {members ? (
                <Box
                  height="600px"
                  sx={{
                    '& .MuiDataGrid-root': {
                      border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                      borderBottom: 'none',
                    },
                    '& .name-column--cell': {
                      color: colors.greenAccent[300],
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      backgroundColor: colors.primary[400],
                    },
                    '& .MuiDataGrid-footerContainer': {
                      borderTop: 'none',
                      backgroundColor: colors.blueAccent[700],
                    },
                    '& .MuiCheckbox-root': {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                      color: `${colors.grey[100]} !important`,
                      fontSize: 14,
                    },
                  }}
                >
                  <DataGrid
                    rows={members}
                    columns={columns}
                    getRowId={(row) => row.M_ID}
                    // components={{ Toolbar: CustomToolbar }}
                    autoPageSize
                  />
                </Box>
              ) : (
                <Typography variant="p" color={colors.grey[300]} paddingLeft="8px">
                  No Members found
                </Typography>
              )}
            </Box> */}
          </Box>
        </Box>
      )}

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
};

export default PassengerDetails;
