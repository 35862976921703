import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const AddBooking = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');

  const [booking, setBooking] = useState({
    contractNo: '',
    passengerId: '',
    destinationId: '',
    hotel: '',
    bus: '',
    startDestination: '',
    guideId: '',
    partner: '',
    notes: '',
    bookingStartDate: '',
    bookingEndDate: '',
    paymentStatus: '',
  });

  const [countryNames, setCountryNames] = useState([]);
  // const [packageNames, setPackageNames] = useState([]);
  const [guideNames, setGuideNames] = useState([]);
  const [passengerNames, setPassengerNames] = useState([]);

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountryNames(data);
  };

  // const getPackageNames = async (id) => {
  //   const res = await fetch(`${process.env.REACT_APP_APILINK}/countrypackages/${id}`, { headers: { token } });
  //   const data = await res.json();
  //   if (data.success) {
  //     setPackageNames(data.packages);
  //   }
  // };

  const getGuideNames = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/guidenames/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setGuideNames(data.guides);
    }
  };

  const getPassengerNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passengernames`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setPassengerNames(data.passengers);
    }
  };

  useEffect(() => {
    getCountryNames();
    getPassengerNames();
  }, []);

  useEffect(() => {
    if (booking.destinationId) {
      // getPackageNames(booking.destinationId);
      getGuideNames(booking.destinationId);
    }
  }, [booking.destinationId]);

  const handleChange = (e) => {
    setBooking({ ...booking, [e.target.name]: e.target.value });
  };

  const handleAutoCompleteChange = (name, value) => {
    setBooking({ ...booking, [name]: value });
  };

  const handlevalidation = () => {
    const {
      contractNo,
      passengerId,
      destinationId,
      hotel,
      bus,
      startDestination,
      guideId,
      partner,
      notes,
      bookingStartDate,
      bookingEndDate,
      paymentStatus,
    } = booking;
    if (
      !contractNo ||
      !passengerId ||
      !destinationId ||
      !hotel ||
      !bus ||
      !startDestination ||
      !guideId ||
      !partner ||
      !bookingStartDate ||
      !bookingEndDate ||
      !paymentStatus
    ) {
      setOpenToast(true);
      setToastmessage('Please fill all Details');
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(booking);
    if (handlevalidation()) {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/bookings`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify(booking),
      });
      const data = await res.json();
      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/booking');
          }, 2000);
        }
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="ADD BOOKING" subtitle="Add a New Booking" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '1.25rem',
            paddingBottom: '20px',
          }}
        >
          {/* Contract No */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Contract No"
            onChange={handleChange}
            value={booking.contractNo}
            name="contractNo"
            required
          />

          {/* Country */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Country"
            onChange={(e) => {
              setBooking({ ...booking, [e.target.name]: e.target.value, guideId: '' });
            }}
            value={booking.destinationId}
            name="destinationId"
            select
            // required
          >
            {countryNames &&
              countryNames.map((country) => (
                <MenuItem key={country.CountryID} value={country.CountryID}>
                  {country.CountryName}
                </MenuItem>
              ))}
          </TextField>

          {/* Package */}
          {/* <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Package Name"
            onChange={handleChange}
            value={booking.PackageID}
            name="PackageID"
            select
            // required
          >
            {packageNames &&
              packageNames.map((pkg) => (
                <MenuItem key={pkg.PackageID} value={pkg.PackageID}>
                  {pkg.PackageName}
                </MenuItem>
              ))}
          </TextField> */}

          {/* Guide */}
          {guideNames && (
            <Autocomplete
              options={guideNames}
              value={guideNames.find((guide) => guide.id === booking.guideId) || null}
              getOptionLabel={(option) => `${option.name} ${option.surname} (${option.email})`}
              onChange={(e, value) => {
                handleAutoCompleteChange('guideId', value ? value.id : null);
              }}
              renderInput={(params) => <TextField {...params} variant="filled" label="Guide" />}
            />
          )}

          {/* Passenger */}
          {passengerNames && (
            <Autocomplete
              options={passengerNames}
              value={passengerNames.find((passenger) => passenger.id === booking.passengerId) || null}
              getOptionLabel={(option) => `${option.name} ${option.surname} (${option.email})`}
              onChange={(e, value) => {
                setBooking({ ...booking, passengerId: value ? value.id : null });
              }}
              renderInput={(params) => <TextField {...params} variant="filled" label="Passenger" />}
            />
          )}

          {/* Multiple Passengers */}
          {/* {passengerNames && (
            <Autocomplete
              options={passengerNames}
              multiple
              value={passengerNames.filter((passenger) => booking.P_IDs.includes(passenger.P_ID))}
              getOptionLabel={(option) => `${option.P_Name} ${option.P_Surname} (${option.P_Email})`}
              onChange={(e, value) => {
                setBooking({ ...booking, P_IDs: value.map((passenger) => passenger.P_ID) });
              }}
              renderInput={(params) => <TextField {...params} variant="filled" label="Passengers" />}
            />
          )} */}

          {/* Hotel */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Hotel"
            onChange={handleChange}
            value={booking.hotel}
            name="hotel"
            required
          />

          {/* Bus */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Bus"
            onChange={handleChange}
            value={booking.bus}
            name="bus"
            required
          />

          {/* Start Destination */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Start Destination"
            onChange={handleChange}
            value={booking.startDestination}
            name="startDestination"
            required
          />

          {/* Partner */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Partner"
            onChange={handleChange}
            value={booking.partner}
            name="partner"
            required
          />

          {/* Notes */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Notes"
            onChange={handleChange}
            value={booking.notes}
            name="notes"
          />

          {/* Booking Date */}
          <Box display="flex" gap="10px" flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
            <TextField
              type="date"
              fullWidth
              variant="filled"
              label="Booking Start Date"
              onChange={handleChange}
              value={booking.bookingStartDate}
              name="bookingStartDate"
              InputLabelProps={{ shrink: true, style: { color: colors.grey[300] } }}
              style={{ colorScheme: 'dark' }}
              required
            />
            <TextField
              type="date"
              fullWidth
              variant="filled"
              label="Booking End Date"
              onChange={handleChange}
              value={booking.bookingEndDate}
              name="bookingEndDate"
              InputLabelProps={{ shrink: true, style: { color: colors.grey[300] } }}
              style={{ colorScheme: 'dark' }}
              required
            />
          </Box>

          {/* Payment */}
          <FormControl component="fieldset">
            <Typography variant="h5" color={colors.grey[100]}>
              Payment Status
            </Typography>
            <RadioGroup name="paymentStatus" value={booking.paymentStatus} onChange={handleChange} row required>
              <FormControlLabel value="1" control={<Radio color="success" />} label="Paid" />
              <FormControlLabel value="0" control={<Radio color="success" />} label="Not Paid" />
            </RadioGroup>
          </FormControl>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AddBooking;
