import { Box, Button, CircularProgress, IconButton, Snackbar, useTheme } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { tokens } from '../../../theme';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../../../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const AllGuides = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);

  const [allGuides, setAllGuides] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_APILINK}/guides`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setAllGuides(data.guides);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  // Delete Guide
  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/guides/${id}`, { method: 'DELETE', headers: { token } });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        window.location.reload();
      }
    }
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Guide Full Name',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
      valueGetter: ({ row: { name, surname } }) => (name || surname ? `${name} ${surname}` : '-'),
    },
    {
      field: 'email',
      headerName: 'Guide Email',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'CountryName',
      headerName: 'Location',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'address',
      headerName: 'Guide Address',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'phone',
      headerName: 'Guide Phone No',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'license',
      headerName: 'Guide License',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'vehicleNo',
      headerName: 'Guide Vehicle No',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'Edit',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Link to={`/guide/${cellValues.row.id}`}>
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.greenAccent[700] },
              }}
              variant="contained"
            >
              <EditIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Delete',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.redAccent[600] },
            }}
            variant="contained"
            onClick={() => handleDelete(cellValues.row.id)}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80vh' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" flexWrap="wrap">
        <Header title="GUIDES" subtitle="All guides" />

        <Box>
          <Link to={'/guide/addguide'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <PeopleOutlined sx={{ mr: '10px' }} />
              Add Guide
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="667px"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.grey[100],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            fontSize: 14,
          },
        }}
      >
        <DataGrid
          rows={allGuides}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: CustomToolbar }}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AllGuides;
