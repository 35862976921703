import { Rating } from '@mui/material';
import React from 'react';

const StarRating = ({ value }) => {
  const ratingValue = Number(value);
  let starColor = '';

  if (ratingValue >= 4 && ratingValue <= 5) {
    starColor = '#4caf50'; // Green
  } else if (ratingValue >= 3 && ratingValue < 4) {
    starColor = '#ff9800'; // Orange
  } else if (ratingValue >= 2 && ratingValue < 3) {
    starColor = '#f44336'; // Red
  } else {
    starColor = '#9c27b0'; // Purple
  }
  return <Rating name="feedback-ans" value={ratingValue} precision={0.5} readOnly style={{ color: starColor }} />;
};

export default StarRating;
