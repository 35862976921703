import { Box, Button, IconButton, MenuItem, Snackbar, TextField } from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const AdminUserDetail = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState({
    username: '',
    password: '',
    countryId: '',
  });

  const [countryNames, setCountryNames] = useState([]);

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountryNames(data);
  };

  const getData = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/adminusers/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setDetails({
        ...details,
        username: data.data.username,
        countryId: data.data.countryId,
      });
    }
  };

  useEffect(() => {
    getCountryNames();
    getData();
  }, []);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handlevalidation = () => {
    const { username, countryId } = details;
    if (!countryId) {
      setOpenToast(true);
      setToastmessage('Please choose Destination');
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (handlevalidation()) {
      console.log(details);
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/adminusers/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify(details),
      });
      const data = await res.json();
      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/adminusers');
          }, 2000);
        }
      }
    }
    setLoading(false);
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="ADMIN DETAILS" subtitle="View Admin Details" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '1.25rem',
            paddingBottom: '20px',
          }}
        >
          <TextField
            type="email"
            fullWidth
            variant="filled"
            label="Username"
            onChange={handleChange}
            value={details.username}
            name="username"
            disabled
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#fff',
              },
            }}
          />

          <TextField
            type="password"
            fullWidth
            variant="filled"
            label="New Password"
            onChange={handleChange}
            value={details.password}
            name="password"
          />

          {/* Country */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Destination"
            onChange={(e) => {
              setDetails({ ...details, countryId: e.target.value });
            }}
            value={details.countryId}
            name="countryId"
            select
            required
          >
            {countryNames &&
              countryNames.map((country) => (
                <MenuItem key={country.CountryID} value={country.CountryID}>
                  {country.CountryName}
                </MenuItem>
              ))}
          </TextField>

          <Button
            type="submit"
            disabled={loading}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            {loading ? 'Updating...' : 'Update'}
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AdminUserDetail;
