import { Box, Button, IconButton, Snackbar, useTheme } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { tokens } from '../../../theme';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../../../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DownloadIcon from '@mui/icons-material/Download';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const AllBookings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');

  const [allBookings, setAllBookings] = useState([]);

  const getData = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/bookings`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setAllBookings(data.data);
    }
  };

  // Function to convert backend date to 'YYYY-MM-DD' format
  const convertDate = (backendDate) => {
    const dateObject = new Date(backendDate);
    const day = String(dateObject.getDate()).padStart(2, '0'); // Get day and add leading zero if needed
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and add leading zero if needed
    const year = dateObject.getFullYear(); // Get year
    return `${day}-${month}-${year}`; // Return in 'DD-MM-YYYY' format
  };

  useEffect(() => {
    getData();
  }, []);

  // Delete Excursion
  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/bookings/${id}`, {
      method: 'DELETE',
      headers: { token },
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        getData();
        // window.location.reload();
      }
    }
  };

  const columns = [
    {
      field: 'contractNo',
      headerName: 'Contract No',
      flex: 1,
      minWidth: 70,
    },
    {
      field: 'fullName', // Combine passengerName and passengerSurname
      headerName: 'Passenger',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
      valueGetter: (params) => `${params.row.passengerName || ''} ${params.row.passengerSurname || ''}`, // Create full name for filtering
      renderCell: (params) => (
        <div>{params.row.passengerName ? `${params.row.passengerName} ${params.row.passengerSurname}` : '-'}</div>
      ),
    },
    {
      field: 'destination',
      headerName: 'Destination',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'startDestination',
      headerName: 'Start Destination',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'guideFullName',
      headerName: 'Guide',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => `${params.row.guideName || ''} ${params.row.guideSurname || ''}`, // Concatenating guideName and guideSurname
      renderCell: (params) => <div>{params.value.trim() ? params.value : '-'}</div>,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <div>{params.row.paymentStatus ? 'Paid' : 'Not Paid'}</div>,
    },

    {
      field: 'bookingStartDate',
      headerName: 'Booking Start Date',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <div>{params.row.bookingStartDate ? convertDate(params.row.bookingStartDate) : '-'}</div>,
    },
    {
      field: 'bookingEndDate',
      headerName: 'Booking End Date',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <div>{params.row.bookingEndDate ? convertDate(params.row.bookingEndDate) : '-'}</div>,
    },
    // {
    //   field: 'Additional Bookings',
    //   filterable: false,
    //   renderCell: (cellValues) => {
    //     if (cellValues.row.HasBookingAdditionals) {
    //       return (
    //         <Link to={`/booking/${cellValues.row.BookingID}/additional`}>
    //           <Button
    //             sx={{
    //               backgroundColor: colors.blueAccent[600],
    //               color: colors.grey[100],
    //               ':hover': { backgroundColor: colors.blueAccent[700] },
    //             }}
    //             variant="contained"
    //           >
    //             <VisibilityIcon />
    //           </Button>
    //         </Link>
    //       );
    //     }
    //     return null;
    //   },
    // },
    {
      field: 'View',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Link to={`/booking/${cellValues.row.id}`}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[600],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.blueAccent[700] },
              }}
              variant="contained"
            >
              <VisibilityIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Edit',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Link to={`/booking/${cellValues.row.id}/update`}>
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.greenAccent[700] },
              }}
              variant="contained"
            >
              <EditIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Delete',
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.redAccent[600] },
            }}
            variant="contained"
            onClick={() => handleDelete(cellValues.row.id)}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" flexWrap="wrap">
        <Header title="BOOKINGS" subtitle="All Bookings" />

        <Box display="flex" gap="10px" flexWrap="wrap">
          <Link to={'/booking/import'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                marginRight: '10px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <DownloadIcon sx={{ mr: '10px' }} />
              Import Bookings
            </Button>
          </Link>
          <Link to={'/booking/addBooking'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <CalendarTodayIcon sx={{ mr: '10px' }} />
              Add Booking
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="667px"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            fontSize: 14,
          },
        }}
      >
        <DataGrid
          rows={allBookings}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: CustomToolbar }}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AllBookings;
