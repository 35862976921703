import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const FeedbackDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const token = localStorage.getItem('token');

  const [values, setValues] = useState({
    question: '',
    tooltip: '',
    questionType: '',
    ansOptions: '',
    defaultValue: '',
    countryId: '',
  });

  const [options, setOptions] = useState({
    option1: '',
    option2: '',
    option3: '',
  });

  const [country, setCountry] = useState([]);

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountry(data);
  };

  useEffect(() => {
    getCountryNames();
  }, []);

  useEffect(() => {
    const getFeedbackDetails = async () => {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/feedback/${id}`, { headers: { token } });
      const data = await res.json();
      if (data.success) {
        setValues(data.feedbackQuestion);

        if (data.feedbackQuestion.ansOptions.length > 0) {
          setOptions({
            option1: data.feedbackQuestion.ansOptions[0] || '',
            option2: data.feedbackQuestion.ansOptions[1] || '',
            option3: data.feedbackQuestion.ansOptions[2] || '',
          });
        }
      }
    };
    getFeedbackDetails();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleOptions = (e) => {
    setOptions({ ...options, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!values.question || !values.tooltip || !values.questionType) {
      setOpenToast(true);
      setToastmessage('Please fill all details');
      return;
    }

    let ansOptions = [];
    const optionsArray = Object.values(options);
    for (let i = 0; i < optionsArray.length; i++) {
      if (optionsArray[i] !== '') {
        ansOptions.push(optionsArray[i]);
      }
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/feedback/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify({ ...values, ansOptions }),
      });
      const data = await res.json();
      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/feedback');
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="FEEDBACK QUESTION" subtitle="Edit the Feedback Question" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '10px',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Question"
            onChange={handleChange}
            name="question"
            value={values.question}
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Tooltip"
            onChange={handleChange}
            name="tooltip"
            value={values.tooltip}
            required
          />

          <FormControl component="fieldset">
            <Typography variant="h5" mt="10px" color={colors.grey[100]}>
              Question Type
            </Typography>
            <RadioGroup
              aria-label="questionType"
              name="questionType"
              value={values.questionType}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value !== '1') {
                  setOptions({
                    option1: '',
                    option2: '',
                    option3: '',
                  });
                }
              }}
              row
            >
              <FormControlLabel value={1} control={<Radio color="success" />} label="MCQ" />
              <FormControlLabel value={2} control={<Radio color="success" />} label="Short Answer" />
              <FormControlLabel value={3} control={<Radio color="success" />} label="Rating" />
            </RadioGroup>
          </FormControl>

          {values.questionType === '1' && (
            <Box mb={2}>
              {/* <Typography variant="h5" mb={1} color={colors.grey[100]}>
                Answer Options
              </Typography> */}

              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Option 1"
                    onChange={handleOptions}
                    name="option1"
                    value={options.option1}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Option 2"
                    onChange={handleOptions}
                    name="option2"
                    value={options.option2}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Option 3"
                    onChange={handleOptions}
                    name="option3"
                    value={options.option3}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* <TextField
            fullWidth
            variant="filled"
            type="text"
            label="defaultValue"
            onChange={handleChange}
            name="defaultValue"
            value={values.defaultValue}
          /> */}

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Destination"
            onChange={handleChange}
            value={values.countryId}
            name="countryId"
            select
          >
            {/* Option for universal question */}
            {/* <MenuItem value="">
              <em>All Destinations</em>
            </MenuItem> */}

            {/* Other country options */}
            {country &&
              country.map((country) => (
                <MenuItem key={country.CountryID} value={country.CountryID}>
                  {country.CountryName}
                </MenuItem>
              ))}
          </TextField>
          <Typography variant="body2" color={colors.grey[100]} fontStyle="italic">
            *Leave Destination blank for univeral question
          </Typography>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default FeedbackDetails;
