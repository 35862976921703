import { Box, Button, CircularProgress, IconButton, MenuItem, Snackbar, TextField } from '@mui/material';
import Header from '../../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import ProgressCircle from '../../../components/ProgressCircle';

const ImportBookings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    country: '',
    departureDate: '',
  });
  const [country, setCountry] = useState([]);

  const [file, setFile] = useState(null);

  const token = localStorage.getItem('token');

  const getCountryNames = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countrynames`, { headers: { token } });
    const data = await res.json();
    setCountry(data);
  };

  useEffect(() => {
    getCountryNames();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (file && values.country && values.departureDate) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('country', values.country);
      formData.append('departureDate', values.departureDate);

      try {
        const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/bookings/import`, {
          headers: { token },
          method: 'POST',
          body: formData,
        });
        const data = await res.json();
        setOpenToast(false);
        if (data.message) {
          setOpenToast(true);
          setToastmessage(data.message);
          if (data.success) {
            setTimeout(() => {
              navigate(`/booking`);
            }, 2000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="IMPORT BOOKINGS" subtitle="Import Booking Details using Excel" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap="10px">
          {/* Location / Destination */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Destination"
            onChange={handleChange}
            value={values.country}
            name="country"
            select
            required
          >
            {country &&
              country.map((country) => (
                <MenuItem key={country.CountryID} value={country.CountryID}>
                  {country.CountryName}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            fullWidth
            variant="filled"
            type="date"
            label="Departure Date"
            onChange={handleChange}
            value={values.departureDate}
            name="departureDate"
            InputLabelProps={{ shrink: true, style: { color: colors.grey[300] } }}
            style={{ colorScheme: 'dark' }}
            required
          />

          <label
            htmlFor="file"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              padding: '15px 30px',
              background: colors.primary[400],
              fontSize: '18px',
              fontWeight: 'bold',
              cursor: 'pointer',
              borderRadius: '10px',
            }}
          >
            <CloudUploadIcon /> {file ? file.name : 'Choose file*'}
          </label>
          <input type="file" accept=".xlsx, .xls, .csv" id="file" onChange={(e) => setFile(e.target.files[0])} hidden />
          <Button
            type="submit"
            disabled={loading}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            {loading ? (
              <>
                <CircularProgress color="secondary" size="20px" sx={{ marginRight: '10px' }} />
                Uploading
              </>
            ) : (
              'Upload File'
            )}
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default ImportBookings;
