import { Box, Button, IconButton, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const AdminLogin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.username === '' || user.password === '') {
      return alert('Please Fill all details');
    }
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/signin`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    const data = await res.json();
    if (data && data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        localStorage.setItem('token', data.token);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Box backgroundColor={colors.primary[400]} p="2rem" m="10px" borderRadius="10px" width="100%" maxWidth="400px">
        <Typography variant="h2" color={colors.grey[100]} textAlign="center" fontWeight="bold" sx={{ mb: '20px' }}>
          ADMIN LOGIN
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box width="100%" display="grid" gap="15px">
            <TextField
              label="Username"
              type="text"
              variant="outlined"
              name="username"
              value={user.username}
              onChange={handleChange}
              required
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              name="password"
              value={user.password}
              onChange={handleChange}
              required
            />
            <Button
              type="submit"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Login
            </Button>
          </Box>
        </form>
      </Box>
      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AdminLogin;
